import axios from 'axios';

export default {
  namespaced: true,
  state: {
    average: [],
    isLoading: false,
  },
  getters: {
    getAverage: (state) => state.average,
  },
  mutations: {
    SET_Loading(state, value) {
      state.isLoading = value;
    },
    SET_Average(state, value) {
      state.average = value;
    },
  },
  actions: {
    async actionHargaPerubahan({ commit }, payload) {
      // const today = new Date().toISOString().slice(0, 10);
      const today = '2022-07-28';
      commit('SET_Loading', true);
      await axios
        .get(`api/harga/perubahan?kabupaten=${payload.idKab}&pasar=${payload.pasar}&tanggal=${today}`)
        .then((response) => {
          commit('SET_Average', response.data.perubahan.harga_result);
          commit('SET_Loading', false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
