<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
</template>

<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

export default {
  setup() {
    const store = useStore();
    onMounted(async () => {
      moment.locale('id');
      await store.dispatch('kabupatenModule/actionHargaMap');
      await store.dispatch('kabupatenModule/actionMapsKab');
      await store.dispatch('tablehargaModule/actionGetTableHarga');
      await store.dispatch('makroekonomiModule/actionGetMakroekonomi');
      await store.dispatch('dataModule/actionGetKomoditas');
      await store.dispatch('InflasiIndex/actionInflasijateng');
      await store.dispatch('InflasiIndex/actionInflasiDaerah');
      await store.dispatch('produksiModule/actionGetProduksi');
    });
  },
};
</script>

<style lang="scss"></style>
