<template>
  <div>
    <h1 class="text-2xl text-white text-center mb-5">Inflasi Kabupaten / Kota (%)</h1>
    <div class="overflow-x-auto">
      <table class="min-w-full leading-normal rounded-md">
        <tbody>
          <tr v-for="item in dataDaerah" :key="item" class="">
            <td class="text-left py-2 px-4 text-white font-light">
              <button class="text-blue-200" @click="handleClick(item.kabupaten_id)">
                {{ item.kabupaten_title }}
              </button>
            </td>
            <td class="text-left py-2 px-4 text-white font-light">{{ item.bulan }}</td>
            <td class="text-left py-2 px-4 text-white font-light">{{ item.percent }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="loadingspinner" v-if="isLoading"></div>
    <div v-show="isId != null" class="w-full space-y-3 mt-10">
      <h5 class="text-white font-medium text-lg">
        Inflasi {{ dataKab?.kabupaten_title }} - {{ dataKab?.percent }}%
      </h5>
      <Accordion v-for="item in dataDaerahByKab" :key="item" :data="item" />
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion/index.vue';
// import Card from '@/components/Card/index.vue';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';

export default {
  components: {
    Accordion,
    // Card,
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.getters['InflasiIndex/loading']);
    const dataDaerah = computed(() => store.getters['InflasiIndex/getInflasiDaerah']);
    const dataDaerahByKab = computed(() => store.getters['InflasiIndex/getInflasiDaerahById']);
    // console.log(dataDaerah.value);
    const isId = ref(null);
    const dataKab = ref(null);

    const handleClick = (value) => {
      store.dispatch('InflasiIndex/actionInflasiDaerahById', value);
      // eslint-disable-next-line eqeqeq
      const kaById = dataDaerah.value.find((item) => item.kabupaten_id == value);
      dataKab.value = kaById;
      console.log(value);
      isId.value = value;
    };

    return { dataDaerah, dataDaerahByKab, handleClick, isId, dataKab, isLoading };
  },
};
</script>

<style lang="scss" scoped>
tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.3);
}
</style>
