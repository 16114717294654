<template>
  <div>
    <h1 class="text-xl text-white text-center mb-5">Tabel Harga Berdasarkan Komoditas</h1>
    <div class="grid grid-cols-12">
      <div class="md:col-span-4 col-span-12 pr-4">
        <Card>
          <div class="text-white">
            <!-- <h5 class="font-medium mb-3">Komoditas</h5> -->
            <!-- <div class="">
              <label for="harga" class="block mb-2 font-medium text-white whitespace-nowrap"
                >Komoditas</label
              >
              <select
                id="harga"
                class="bg-gray-50 py-1 text-gray-900 text-sm focus:ring-0 focus:border-0 block w-full"
                v-model="state.komoditi"
              >
                <option v-for="item in Komoditi" :key="item.ko_id" :value="item.ko_id">
                  {{ item.ko_komoditi }}
                </option>
              </select>
            </div> -->
            <div class="">
              <label
                for="komoditi_multiple"
                class="block mb-2 font-medium text-white whitespace-nowrap"
                >Komoditas</label
              >
              <select
                multiple
                id="komoditi_multiple"
                class="select_multiple h-52 text-sm focus:ring-0 focus:border-0 block w-full p-2.5"
                v-model="state.komoditi"
              >
                <!-- <option selected>Choose countries</option> -->
                <option v-for="item in Komoditi" :key="item.ko_id" :value="item.ko_id">
                  {{ item.ko_komoditi }}
                </option>
              </select>
            </div>
            <!-- <div class="h-52 overflow-auto scrollbar">
              <div
                v-for="item in Komoditi"
                :key="item.ko_id"
                class="flex items-center whitespace-nowrap pl-3"
              >
                <input
                  :id="item.ko_id"
                  name="komoditasCheck"
                  type="checkbox"
                  class="h-4 w-4 focus:ring-0 focus:outline-none focus:border-0 border-0"
                  :value="item.ko_id"
                  v-model="state.komoditi"
                />
                <label :for="item.ko_id" class="ml-2 block text-sm truncate">{{
                  item.ko_komoditi
                }}</label>
              </div>
            </div> -->
            <h5 class="font-medium my-3">Provinsi/Kabupaten/Kota</h5>
            <div class="h-52 overflow-auto scrollbar">
              <div
                v-for="item in Kabupaten"
                :key="item.ka_id"
                class="flex items-center whitespace-nowrap pl-3"
              >
                <input
                  :id="item.ka_id"
                  name="komoditasCheck"
                  type="checkbox"
                  class="h-4 w-4 focus:ring-0 focus:outline-none focus:border-0 border-0"
                  :value="item.ka_id"
                  v-model="state.kab"
                />
                <label :for="item.ko_id" class="ml-2 block text-sm truncate">{{
                  item.ka_kabupaten
                }}</label>
              </div>
            </div>
            <div class="mt-10">
              <label for="harga" class="block mb-2 text-sm text-white whitespace-nowrap"
                >Tipe Laporan</label
              >
              <select
                id="harga"
                class="bg-gray-50 py-1 text-gray-900 text-sm focus:ring-0 focus:border-0 block w-full"
                v-model="state.tipe"
              >
                <option v-for="item in TipeLaporan" :key="item" :value="item.value">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="xl:flex justify-between">
              <div>
                <label for="date" class="block mb-2 text-sm text-white">Dari Tanggal</label>
                <input
                  id="date"
                  type="date"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-1"
                  placeholder="Date"
                  v-model="state.start"
                />
              </div>
              <div>
                <label for="date" class="block mb-2 text-sm text-white">Sampai Tanggal</label>
                <input
                  id="date"
                  type="date"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-1"
                  placeholder="Date"
                  v-model="state.end"
                />
              </div>
            </div>
            <button
              @click="handleSubmit()"
              type="button"
              class="bg-lightBlue-600 px-3 py-1 my-4 w-full"
            >
              Lihat Hasil
            </button>
            <export-excel
              class="border-lightBlue-600 border-2 px-3 py-1 text-lightBlue-600 w-full text-center cursor-pointer"
              :data="dataExcel"
              worksheet="My Worksheet"
              name="dataharga.xls"
              title="Data Harga"
            >
              Download Data
            </export-excel>
          </div>
        </Card>
      </div>
      <div class="md:col-span-8 col-span-12">
        <div class="mb-4">
          <h3 class="text-white">Perkembangan Harga Pangan</h3>
          <div class="grid grid-cols-12 text-sm text-white font-light">
            <span class="col-span-2">Periode</span>
            <span class="col-span-1">:</span>
            <span class="col-span-8">
              {{ momentDay(dates[0]?.date) }} -
              {{ momentDay(dates[dates.length - 1]?.date) }}
            </span>
          </div>
          <div class="grid grid-cols-12 text-sm text-white font-light">
            <span class="col-span-2">Komoditas</span>
            <span class="col-span-1">:</span>
            <span class="col-span-8">{{ state.nameKomoditi }}</span>
          </div>
          <div class="grid grid-cols-12 text-sm text-white font-light">
            <span class="col-span-2">Tipe Laporan</span>
            <span class="col-span-1">:</span>
            <span class="col-span-8">{{ state.nameTipe }}</span>
          </div>
        </div>
        <div class="overflow-x-auto">
          <table class="min-w-full leading-normal rounded-md relative">
            <thead class="bg-primary text-white rounded-t-md">
              <tr>
                <th class="py-2 px-4 font-semibold text-sm text-center">No.</th>
                <th class="py-2 px-4 font-semibold text-sm text-left">Kabupaten/Kota</th>
                <th
                  v-for="item in TableHarga.dates"
                  :key="item"
                  class="py-2 px-4 font-semibold text-sm text-left whitespace-nowrap"
                >
                  {{ item.date }}
                </th>
              </tr>
            </thead>
            <tbody>
              <div class="loadingspinner" v-if="isLoading"></div>
              <tr v-for="(item, index) in TableHarga.harga" :key="index" class="">
                <td class="py-2 px-4 text-white font-light text-center">{{ index }}</td>
                <td class="py-2 px-4 text-white font-light whitespace-nowrap">
                  {{ item.kabupaten }}
                </td>
                <td v-for="date in dates" :key="date" class="py-2 px-4 text-white font-light">
                  {{ Object.values(item.harga_detail)[0].harga }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card/index.vue';
import { ref, computed, reactive } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

export default {
  components: {
    Card,
  },
  setup() {
    const store = useStore();
    const checkedNames = ref([]);
    const checkedKomoditi = ref([]);
    const checkedKab = ref([]);
    const state = reactive({
      komoditi: '',
      nameKomoditi: 'Beras IR 64 Premium (Kg)',
      tipe: '',
      nameTipe: 'Harian',
      start: '',
      end: '',
      kab: [],
    });
    const dataExcel = ref([]);
    const headerExcel = ref([]);
    const isLoading = computed(() => store.state.tablehargaModule.isLoading);
    const Komoditi = computed(() => store.getters['dataModule/getDataKomoditas']);
    // const KomoditiById = computed(() => store.getters);
    // console.log(KomoditiById.value);
    const Kabupaten = computed(() => store.getters['kabupatenModule/getAllKab']);
    const TableHarga = computed(() => store.getters['tablehargaModule/getTableHarga']);
    const dates = computed(() => store.getters['tablehargaModule/getDates']);
    const Harga = computed(() => Object.values(store.getters['tablehargaModule/getHarga']));
    // eslint-disable-next-line array-callback-return
    Harga.value.map((item) => {
      const data = {
        Kabupaten: item.kabupaten,
      };
      // eslint-disable-next-line array-callback-return
      dates.value.map((date) => {
        const key = date.date;
        data[key] = Object.values(item.harga_detail)[0].harga;
      });
      dataExcel.value.push(data);
    });

    function momentDay(value) {
      return moment(value).format('ll');
    }

    const handleSubmit = () => {
      store.dispatch('tablehargaModule/actionGetTableHargaPayload', state);
      // eslint-disable-next-line eqeqeq
      const koById = Komoditi.value.find((item) => item.ko_id == state.komoditi);
      state.nameKomoditi = koById.ko_komoditi;
      // eslint-disable-next-line eqeqeq
      const tipeById = TipeLaporan.find((item) => item.value == state.tipe);
      state.nameTipe = tipeById.name;
      // console.log(state);
    };

    const TipeLaporan = [
      { name: 'Laporan Harian', value: '1' },
      { name: 'Laporan Mingguan', value: '2' },
      { name: 'Laporan Bulanan', value: '3' },
      { name: 'Laporan Tahunan', value: '4' },
    ];

    return {
      checkedNames,
      handleSubmit,
      checkedKomoditi,
      checkedKab,
      Komoditi,
      Kabupaten,
      TipeLaporan,
      TableHarga,
      // changeType,
      // changeDateStart,
      // changeDateEnd,
      state,
      dates,
      momentDay,
      Harga,
      dataExcel,
      isLoading,
      headerExcel,
    };
  },
};
</script>

<style scoped>
table {
  min-height: 200px;
}
tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.3);
}

.select_multiple {
  background: white;
  color: rgb(53, 53, 53);
}
</style>
