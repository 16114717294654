import axios from 'axios';

export default {
  namespaced: true,
  state: {
    tableHarga: [],
    dates: [],
    harga: [],
    isLoading: false,
  },
  getters: {
    getTableHarga: (state) => state.tableHarga,
    getDates: (state) => state.dates,
    getHarga: (state) => state.harga,
  },
  mutations: {
    SET_Loading(state, value) {
      state.isLoading = value;
    },
    SET_TableHarga(state, value) {
      state.tableHarga = value;
    },
    SET_Dates(state, value) {
      state.dates = [];
      state.dates = value;
    },
    SET_Harga(state, value) {
      state.harga = [];
      state.harga = value;
    },
  },
  actions: {
    async actionGetTableHarga({ commit }) {
      // const today = new Date().toISOString().slice(0, 10);
      // const today = '2022-06-28';
      // console.log(payload);
      commit('SET_Loading', true);
      await axios
        .get('api/harga/tabel_harga?komoditas=')
        .then((response) => {
          // console.log(response.data);
          commit('SET_TableHarga', response.data);
          commit('SET_Dates', response.data.dates);
          commit('SET_Harga', response.data.harga);
          commit('SET_Loading', false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async actionGetTableHargaPayload({ commit }, payload) {
      // const today = new Date().toISOString().slice(0, 10);
      // const today = '2022-06-28';
      // console.log(payload);
      commit('SET_Loading', true);
      await axios
        .get(`api/harga/tabel_harga?komoditas=${payload.komoditi}&kabupaten=${payload.kab}&dari_tanggal=${payload.start}&sampai_tanggal=${payload.end}&tipe=${payload.tipe}`)
        .then((response) => {
          // console.log(response.data);
          commit('SET_TableHarga', response.data);
          commit('SET_Dates', response.data.dates);
          commit('SET_Harga', response.data.harga);
          commit('SET_Loading', false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
