<template>
  <div class="text-center space-y-5">
    <h3 class="text-white text-2xl font-semibold">Harga rata rata dan Perubahan</h3>
    <Card class="flex justify-around py-5 items-center averageselect mx-auto">
      <span class="text-white font-semibold">{{ today() }}</span>
      <div>
        <label for="harga" class="block mb-2 text-sm text-white">Kabupaten/Kota</label>
        <select
          @change="changeKab($event)"
          id="harga"
          class="bg-gray-50 py-1 text-gray-900 text-sm rounded-md focus:ring-0 focus:border-0 block w-full"
        >
          <!-- <option value="0">Prov. Jawa Tengah</option> -->
          <option v-for="item in namekab" :key="item.ka_id" :value="item.ka_id">
            {{ item.ka_kabupaten }}
          </option>
        </select>
      </div>
      <!-- <div>
        <label for="harga" class="block mb-2 text-sm text-white">Pilih Pasar</label>
        <select
          @change="changePasar($event)"
          id="harga"
          class="bg-gray-50 py-1 text-gray-900 text-sm rounded-md focus:ring-0 focus:border-0 block w-full"
        >
          <option v-for="item in dataPasar" :key="item" :value="item.value">
            {{ item.name }}
          </option>
        </select>
      </div> -->
    </Card>
    <div class="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 relative">
      <div class="loadingspinner" v-if="isLoading"></div>
      <Card v-for="data in dataAverage" :key="data" class="average">
        <div class="title">
          <span class="text-sm">{{ data.nama_komoditas }}</span>
        </div>
        <div class="flex justify-between items-center p-5">
          <div>
            <LineChart :data="data.detail" />
          </div>
          <div class="desc ml-5 text-right">
            <div>
              <span class="block text-white text-2xl font-semibold">
                {{ $filters.idr(data.harga, true) }}
              </span>
              <span class="text-white font-light block -mt-2">per kg</span>
            </div>
            <div
              v-if="data.harga > 20000"
              class="bg-green-700 text-white rounded px-2 font-semibold text-xs mt-3 whitespace-nowrap"
            >
              <i class="fa-solid fa-arrow-trend-up" />
              <span class="ml-2">2.46% - Rp900</span>
            </div>
            <div
              v-if="data.harga < 20000"
              class="bg-red-700 text-white rounded px-2 font-semibold text-xs mt-3 whitespace-nowrap"
            >
              <i class="fa-solid fa-arrow-trend-down" />
              <span class="ml-2">0.38% - Rp200</span>
            </div>
          </div>
        </div>
      </Card>
    </div>
    <div v-if="length != average.length" @click="loadMore()">
      <button type="button" class="text-white bg-blue-600 py-1 px-3 rounded-md">semuanya</button>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/Chart/LineChart.vue';
import Card from '@/components/Card/index.vue';
import { useStore } from 'vuex';
import { computed, onMounted, reactive, ref } from 'vue';
import moment from 'moment';

export default {
  components: {
    LineChart,
    Card,
  },
  setup() {
    const store = useStore();
    const length = ref(12);
    const average = computed(() => store.getters['perubahanModule/getAverage']);
    const namekab = computed(() => store.getters['kabupatenModule/getAllKab']);
    const dataAverage = computed(() => average.value.slice(0, length.value));
    const isLoading = computed(() => store.state.perubahanModule.isLoading);

    function today() {
      // return new Date().toISOString().slice(0, 10);
      return moment().format('LL');
    }
    const loadMore = () => {
      if (length.value > average.value.length);
      length.value = average.value.length;
    };
    // console.log(namekab.value);
    const state = reactive({
      pasar: '',
      idKab: 33,
    });
    const changeKab = (e) => {
      console.log(e.target.value);
      store.dispatch('perubahanModule/actionHargaPerubahan', { idKab: e.target.value, pasar: '' });
    };
    const changePasar = (e) => {
      console.log(e.target.value);
    };
    const dataPasar = [
      { name: 'Pasar Tradisional', value: 1 },
      { name: 'Pasar Modern', value: 2 },
    ];
    onMounted(async () => {
      await store.dispatch('perubahanModule/actionHargaPerubahan', state);
      // await store.dispatch('kabupatenModule/actionMapsKab');
    });
    return {
      dataAverage,
      loadMore,
      dataPasar,
      namekab,
      state,
      changeKab,
      changePasar,
      today,
      length,
      average,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.average {
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 0;
  .title {
    background: #263751;
    color: white;
  }
}
.averageselect {
  width: 100%;
}

@media screen and (min-width: 680px) {
  .averageselect {
    width: 50vw;
  }
}
</style>
