/* eslint-disable linebreak-style */
// eslint-disable-next-line linebreak-style
/* eslint-disable prefer-template */
/* eslint-disable linebreak-style */
const axios = require('axios');

const set = (key) => (state, val) => {
  state[key] = val;
};

function initialState() {
  return {
    data: [],
    total: 0,
    query: {},
    loading: false,
    InflasiJateng: [],
    InflasiDaerah: [],
    InflasiDaerahById: [],
  };
}

// const route = process.env.VUE_APP_ROOT_API + 'harga/donut_inflasi';

const getters = {
  data: (state) => state.data,
  total: (state) => state.total,
  loading: (state) => state.loading,
  getInflasiJateng: (state) => state.InflasiJateng,
  getInflasiDaerah: (state) => state.InflasiDaerah,
  getInflasiDaerahById: (state) => state.InflasiDaerahById,
};
const actions = {
  fetchIndexData({ commit, state }) {
    commit('setLoading', true);
    axios
      .get('api/harga/donut_inflasi', { params: state.query })
      .then((response) => {
        // console.log(response.data.inflasi);
        commit('setData', response.data.inflasi);
        commit('setTotal', response.data.total);
      })
      .catch((error) => {
        // eslint-disable-next-line no-undef
        message = error.response.data.message || error.message;
        // TODO error handling
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
  actionInflasijateng({ commit }) {
    commit('setLoading', true);
    axios.get('json/sub/inflasi/').then((response) => {
      // console.log(response.data.data_makro);
      commit('setInflasiJateng', response.data.data_makro);
    })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
  async actionInflasiDaerah({ commit }) {
    commit('setLoading', true);
    await axios.get('json/sub/inflasi_daerah/').then((response) => {
      // console.log(response.data);
      commit('setInflasiDaerah', response.data.inflasi_daerah);
      commit('setLoading', false);
    })
      .catch((error) => {
        console.log(error);
      });
  },
  async actionInflasiDaerahById({ commit }, value) {
    commit('setLoading', true);
    await axios.get(`json/sub/inflasi_daerah/${value}`)
      .then((response) => {
        // console.log(response.data);
        commit('setInflasiDaerahById', response.data.inflasi_daerah);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
  setQuery({ commit }, value) {
    // eslint-disable-next-line no-undef
    commit('setQuery', JSON.parse(JSON.stringify(value)));
  },
  resetState({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  setData: set('data'),
  setTotal: set('total'),
  setQuery(state, query) {
    // eslint-disable-next-line no-param-reassign
    query.page = (query.offset + query.limit) / query.limit;
    state.query = query;
  },
  setInflasiJateng(state, value) {
    state.InflasiJateng = value;
  },
  setInflasiDaerah(state, value) {
    state.InflasiDaerah = value;
  },
  setInflasiDaerahById(state, value) {
    state.InflasiDaerahById = value;
  },
  setLoading: set('loading'),
  resetState(state) {
    Object.assign(state, initialState());
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
