<template>
  <div>
    <div v-show="jenisHarga === 1" class="pb-5">
      <div class="rangecolor grid grid-cols-6">
        <div id="satu"></div>
        <div id="dua"></div>
        <div id="tiga"></div>
        <div id="empat"></div>
        <div id="lima"></div>
        <div id="enam"></div>
      </div>
    </div>

    <div
      v-show="jenisHarga !== 1"
      class="boxrangecolor grid xl:grid-cols-4 md:grid-cols-3 grid-cols-1 p-2 mt-3"
    >
      <div
        v-for="item in datas"
        :key="item"
        :style="{ color: `${item.color}` }"
        class="text-xs md:text-sm whitespace-nowrap"
      >
        <i class="fa-solid fa-circle py-2 px-1" />
        <span>{{ item.value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    jenisHarga: Number,
  },
  setup() {
    const datas = [
      {
        value: 'Turun > 10%',
        color: '#FF529B',
      },
      {
        value: 'Turun 5% - 10%',
        color: '#CA01EB',
      },
      {
        value: 'Turun 0% - 5%',
        color: '#E7961D',
      },
      {
        value: 'Naik 0%-5%',
        color: '#27AE65',
      },
      {
        value: 'Naik 5% - 10%',
        color: '#FFE000',
      },
      {
        value: 'Naik > 10%',
        color: '#DC2731',
      },
      {
        value: 'Tidak update',
        color: '#D1CFD7',
      },
      {
        value: 'Tidak ada data',
        color: '#FFF',
      },
    ];

    return { datas };
  },
};
</script>

<style lang="scss" scoped>
.rangecolor {
  position: relative;
  width: 30vw;
  height: 15px;
  #satu {
    background: #d1cfd7;

    &::before {
      content: 'Harga Belum Update';
      position: absolute;
      bottom: -20px;
      color: white;
      font-size: 12px;
    }
  }
  #dua {
    background: #036c30;
  }
  #tiga {
    background: #1c894a;
  }
  #empat {
    background: #27ae65;
  }
  #lima {
    background: #f52f39;
  }
  #enam {
    background: #ba161e;
    &::before {
      color: white;
      content: 'Harga tertinggi';
      position: absolute;
      bottom: -20px;
      right: 0;
      font-size: 12px;
    }
  }
}

.boxrangecolor.borderCustom {
  border: 1px dashed rgb(203, 203, 203);
  border-radius: 5px;
}
</style>
