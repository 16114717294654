<script setup>
import Navbar from '@/components/Navbar/index.vue';
import Berita from '@/components/Berita/index.vue';
import Inflasi from '@/components/Inflasi/index.vue';
import Card from '@/components/Card/index.vue';
</script>

<template>
  <Navbar />
  <div class="content py-5 pb-10">
    <div class="container xl mx-auto">
      <Card class="grid grid-cols-12 items-center">
        <img src="@/assets/image/sihati.png" alt="sihati" class="xl:col-span-3 col-span-12" />
        <Inflasi
          v-for="val in data"
          :key="val.index"
          :value="val.percent.replace('%', '')"
          :title="val.text"
          class="xl:col-span-3 md:col-span-4 col-span-12"
        />
      </Card>
      <router-view />
    </div>
  </div>
  <Berita />
</template>

<style lang="scss" scoped>
.content {
  background: radial-gradient(50% 50% at 50% 50%, #4b6aaa 0%, #21457d 100%);
  min-height: 90vh;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    // console.log(this.fetchIndexData());
    return {
      query: { sort: 'id', order: 'desc', limit: 100, s: '' },
    };
  },
  beforeUnmount() {
    this.resetState();
  },
  computed: {
    ...mapGetters('InflasiIndex', ['data', 'total', 'loading']),
  },
  watch: {
    query: {
      immediate: true,
      handler(query) {
        // console.log(query);
        this.setQuery(query);
        this.fetchIndexData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('InflasiIndex', ['fetchIndexData', 'setQuery', 'resetState']),
  },
};
</script>
