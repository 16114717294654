<template>
  <div class="relative text-white">
    <div class="grid grid-cols-12 bg-biruGelap py-2 px-3">
      <span class="col-span-8">Kabupaten/Kota</span>
      <span class="col-span-4">{{ jenisHarga === 2 ? 'Persen' : 'Harga' }}</span>
    </div>

    <div class="kab divide-y divide-solid divide-gray-100 overflow-auto scrollbar">
      <div v-if="isLoading" class="loadingspinner"></div>
      <div
        v-show="!isLoading"
        v-for="data in dataHargaKab"
        :key="data.kabupaten_id"
        class="grid grid-cols-12 py-2 px-3"
      >
        <span class="col-span-8">{{ data.ka_name }}</span>
        <span class="col-span-4">{{ data?.harga ? data.harga : $filters.idr(0) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const dataHargaKab = computed(() => store.getters['kabupatenModule/getAll']);
    const isLoading = computed(() => store.state.kabupatenModule.isLoading);
    const jenisHarga = computed(() => store.state.kabupatenModule.jenisHarga);
    // console.log(dataHargaKab.value);
    return { dataHargaKab, isLoading, jenisHarga };
  },
};
</script>

<style lang="scss" scoped>
.kab {
  max-height: 35rem;
}
</style>
