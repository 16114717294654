<template>
  <div class="flex fixed bottom-0 z-10 sm:text-base text-sm">
    <div class="bg-biruGelap text-white text-center harga">
      <span>Harga hari ini</span>
    </div>
    <div class="bg-primary bg-opacity-80 text-white">
      <marquee-text :duration="500" :paused="paused">
        <ul class="flex space-x-10">
          <li
            v-for="item in dataAll"
            @mouseenter="paused = !paused"
            @mouseleave="paused = false"
            :key="item.kabupaten_id"
          >
            <span
              >{{ komoditas }} ( {{ item.kabupaten }} ) {{ item.tanggal }} - {{ item.harga }}</span
            >
          </li>
        </ul>
      </marquee-text>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { onMounted, computed, ref } from 'vue';
import MarqueeText from 'vue-marquee-text-component';

const store = useStore();
const paused = ref(false);
// eslint-disable-next-line no-unused-vars
const dataAll = computed(() => store.getters['kabupatenModule/getHarga']);
const komoditas = computed(() => store.getters['kabupatenModule/getKomoditas']);
// console.log(komoditas.value);

onMounted(async () => {
  // await store.dispatch('kabupatenModule/actionMapsKab');
  // await store.dispatch('kabupatenModule/actionHargaMap');
});
</script>

<style lang="scss" scoped>
.harga {
  min-width: 20vw;
}

@media screen and (min-width: 1010px) {
  .harga {
    min-width: 10vw;
  }
}
</style>
