<template>
  <Bar :chart-options="chartOptions" :chart-data="chartData" :height="200" />
  <!-- <div>{{ getHistogram }}</div> -->
</template>

<script>
// import { mapState } from 'vuex';
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { computed, reactive, watchEffect } from 'vue';
import { useStore } from 'vuex';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'BarChart',
  components: { Bar },
  setup() {
    const store = useStore();
    const dataHistogram = computed(() => store.getters['kabupatenModule/getHistogram']);
    // console.log(dataHistogram.value.category);

    // watch(() => dataHistogram.value, chartData, { immediate: true });

    const chartData = reactive({
      // labels: ['January', 'February', 'March'],
      // labels: dataHistogram.value.category,
      labels: null,
      datasets: [
        {
          label: 'Data',
          backgroundColor: null,
          // backgroundColor: dataHistogram.value.colors,
          data: null,
          // data: dataHistogram.value.data,
          // data: [40, 20, 12],
        },
      ],
    });

    const chartOptions = reactive({
      responsive: true,
    });

    watchEffect(
      // eslint-disable-next-line no-return-assign
      () => (chartData.labels = dataHistogram.value.category),
      (chartData.datasets[0].data = dataHistogram.value.data),
      (chartData.datasets[0].backgroundColor = dataHistogram.value.colors),
    );
    return { chartData, chartOptions };
  },
  //   props: {
  //     chartId: {
  //       type: String,
  //       default: 'bar-chart',
  //     },
  //     datasetIdKey: {
  //       type: String,
  //       default: 'label',
  //     },
  //     width: {
  //       type: Number,
  //       default: 400,
  //     },
  //     height: {
  //       type: Number,
  //       default: 400,
  //     },
  //     cssClasses: {
  //       default: '',
  //       type: String,
  //     },
  //     styles: {
  //       type: Object,
  //       default: () => {},
  //     },
  //     plugins: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  // data() {
  //   console.log(this);
  //   return {
  //     chartData: {
  //       labels: ['January', 'February', 'March'],
  //       // labels: this.getHistogram,
  //       datasets: [
  //         {
  //           label: 'Data One',
  //           backgroundColor: '#f87979',
  //           // data: this.getHistogram,
  //           data: [40, 20, 12],
  //         },
  //       ],
  //     },
  //     chartOptions: {
  //       responsive: true,
  //     },
  //   };
  // },
  // computed: {
  //   // ...mapState('kabupatenModule', ['histogram']),
  //   getData() {
  //     return this.$store.getters['kabupatenModule/getHistogram'].category;
  //   },
  // },
  // created() {
  //   console.log(this.getData);
  // },
};
</script>
