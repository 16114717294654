<template>
  <div class="text-center space-y-5">
    <h3 class="text-white text-xl">Data Produksi dan Harga Produsen</h3>
    <h4 class="text-white text-md">
      Prognosa Ketersediaan dan Kebutuhan Pangan Provinsi Jawa Tengah Januari s/d Maret 2022
    </h4>
    <Card class="hidden md:grid-cols-3 grid-cols-1 gap-5 py-5 items-end dataselect mx-auto">
      <div>
        <Select label="Pilih Lokasi" :data="dataLokasi" />
      </div>
      <div>
        <Select label="Pilih TK" :data="dataTK" />
      </div>
      <button type="button" class="bg-blue-600 text-white rounded-md px-2 py-3">Muat Filter</button>
    </Card>
    <div class="overflow-x-auto rounded-md">
      <table class="min-w-full leading-normal rounded-md">
        <thead class="bg-primary text-white rounded-t-md">
          <tr>
            <th class="py-2 px-4 font-semibold text-sm text-center whitespace-nowrap">
              Bahan Pangan Pokok
            </th>
            <th class="py-2 px-4 font-semibold text-sm text-left">Ketersediaan (TON)</th>
            <th class="py-2 px-4 font-semibold text-sm text-left">Kebutuhan (TON)</th>
            <th class="py-2 px-4 font-semibold text-sm text-left">Surplus/Minus (TON)</th>
            <th class="py-2 px-4 font-semibold text-sm text-left">Tk.Produsen (Rp)</th>
            <th class="py-2 px-4 font-semibold text-sm text-left">HET/HAP (Rp)</th>
            <th class="py-2 px-4 font-semibold text-sm text-left">Tk.Konsumen (Rp)</th>
            <th class="py-2 px-4 font-semibold text-sm text-left">HET/HAP (Rp)</th>
            <th class="py-2 px-4 font-semibold text-sm text-left">Tahun</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataProduksi" :key="item" class="bg-primary bg-opacity-50">
            <td class="text-left py-2 px-4 text-white font-light">{{ item.komoditas }}</td>
            <td class="text-center py-2 px-4 text-white font-light">{{ item.ketersediaan }}</td>
            <td class="text-center py-2 px-4 text-white font-light">{{ item.kebutuhan }}</td>
            <td class="text-center py-2 px-4 text-white font-light">{{ item.surplus_minus }}</td>
            <td class="text-center py-2 px-4 text-white font-light">{{ item.tk_produsen }}</td>
            <td class="text-center py-2 px-4 text-white font-light">{{ item.het_hap_produsen }}</td>
            <td class="text-center py-2 px-4 text-white font-light">{{ item.tk_konsumen }}</td>
            <td class="text-center py-2 px-4 text-white font-light">{{ item.het_hap_konsumen }}</td>
            <td class="text-center py-2 px-4 text-white font-light">{{ item.tahun }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import Select from '@/components/Select/index.vue';
import Card from '@/components/Card/index.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const dataProduksi = computed(() => store.getters['produksiModule/getProduksi']);
// console.log(dataProduksi.value);

const dataLokasi = [
  { value: 'Kabupaten Cilacap' },
  { value: 'Kabupaten Purworejo' },
  { value: 'Kabupaten Kebumen' },
];
const dataTK = [{ value: 'Pasar Tradisional' }, { value: 'Pasar Modern' }, { value: 'Pasar' }];
</script>

<style lang="scss" scoped>
.dataselect {
  width: 100%;
}

tr:nth-child(even) {
  background: transparent;
}

@media screen and (min-width: 680px) {
  .dataselect {
    width: 50vw;
  }
}
</style>
